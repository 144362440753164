import React from 'react';
import Spells from '../toolbar/Spells';
import MonstersWidget from '../toolbar/MonstersWidget';
import '../../index.css';
import '../../App.css';
import StatusWidget from '../toolbar/StatusWidget';
import {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc, getDocs, query, where, deleteDoc, doc, getDoc } from "firebase/firestore";
import {db, auth} from '../FirebaseConfig';

const Encounter = () => {
    const [currentEncounter, setCurrentEncounter] = useState([]);
    const [currentEncounterIndex, setCurrentEncounterIndex] = useState([]);
    const [encounters, setEncounters] = useState([]);
    const [partymembers, setPartyMembers] = useState([]);
    const [encounterName, setEncounterName] = useState('');
  const getEncountersFromDb = async () => {
    const uid = auth.currentUser.uid;
    const q = query(collection(db, "encounters"), where("uid", "==", uid));
    setEncounters([]);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      setEncounters(encounters => [...encounters, doc.data()]);
      console.log(encounters);
    }
    );
  }
  const notify = (message) => toast(message);

  const deleteEncounterFromDb = (index) => {
    console.log("index is: " + index); 
    const uid = auth.currentUser.uid;
    const q = query(collection(db, "encounters"), where("uid", "==", uid));
    const querySnapshot = getDocs(q);
    querySnapshot.then((querySnapshot) => {
      querySnapshot.forEach((docS) => {
        if (docS.data().encounterName === index) {
          const docRef = doc(db, "encounters", docS.id);
          deleteDoc(docRef)
            .then(() => {
              console.log("Entire Document has been deleted successfully.")
            }).catch(error => {
              console.log(error);
            }).then(() => {
              getEncountersFromDb();
            });

  }})})};

  const getPartyFromDb = async () => {
    const uid = auth.currentUser.uid;
    const q = query(collection(db, "players"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      setPartyMembers(partymembers => [...partymembers, doc.data()]);
      console.log(partymembers);
    }
    );
  }
  const saveCombat = async () => {
    let combatname = encounterName;
    let tempCombat = {
      uid: auth.currentUser.uid,
      combatName: combatname,
      chars: []
    }
      partymembers.forEach(element => {
        const initPlayer = document.getElementById(element.player).value;
        element = {...element, initiative: initPlayer};
        console.log(element);
        tempCombat.chars.push(element);
      },
      

      );  console.log(tempCombat);

      currentEncounter.forEach(element => {
        const initMonster = document.getElementById(element.name + element.counter.toString()).value;
        element = {...element, initiative: initMonster};
        console.log(element);
        tempCombat.chars.push(element);
      });

      console.log(tempCombat);
      const q = collection(db, "combat");
      await addDoc(q, tempCombat);
      console.log("Document written with ID: ", q.id);
      
      notify("Combat Saved");

  }
  const rollInitiative = (initiative) => {

    const randomD20 = Math.floor(Math.random() * 20) + 1;
    console.log(randomD20);
    const parsedInitiative = parseInt(initiative);
    const total =  randomD20 + parsedInitiative;
    return total;
  }

  
  useEffect(() => {
    getEncountersFromDb();
    getPartyFromDb();
  } , [])

  return (
    <div className="App">

      <div class="flex flex-row">
        <div class="basis-1/6 bg-base-300 p-4 flex-row justify-center">
          <h1 className='text-xl font-bold'>Your encounters</h1>
        <ul class="flex mt-4 mb-4 menu menu-compact lg:menu-normal bg-base-100 p-2 rounded-box">
            {encounters.map((encounter, index) => (
              <li class="flex justify-between flex-row m-2" key={index}>
                <button onClick={() => {
                  setCurrentEncounter(encounter.monsters);
                  setEncounterName(encounter.encounterName);
                  setCurrentEncounterIndex(index);
                } }>{encounter.encounterName}</button>
                <button className='btn btn-primary' onClick={() => deleteEncounterFromDb(encounter.encounterName)}>Delete</button>
              </li>

                
            ))}
          </ul>
        </div>
        <div class="p-4 basis-4/6 bg-base-100 flex item-start flex-col flex-wrap">
        <h1 className=' text-xl font-bold'>Monsters</h1>
          <div class="flex flex-row gap-4">
          <ToastContainer />
            {currentEncounter.map((monster, index) => (
              <div key={index}className={!monster.name ? 'bg-accent p-4" mt-4 h-48 card justify-center text-secondary-content' : 'bg-secondary mt-4 h-48 card justify-center text-primary-content'}>
              <div class="card-body p-4">
                <p class="card-title">{!monster.name ? monster.monstername + ' ' + monster.counter :  monster.name + ' ' + monster.counter}</p>
               {!monster.initiative ?  <input id={monster.name + monster.counter.toString()} className='input input-bordered w-full max-w text-tertiary' placeholeder="..."></input> : <input id={monster.name + monster.counter.toString()} className='input input-bordered w-full max-w text-tertiary' placeholeder="..."  value={rollInitiative(monster.initiative)}></input>}
      
              </div>
            </div>
             
            ))}
          </div>
          <div class="flex flex-row gap-4">
          <h1 className=' text-xl font-bold'>Party</h1>
          </div>
          <div class="flex flex-row gap-4 flex flex-row flex-wrap">
            {partymembers.map((member, index) => (
              <div key={index} class="mt-4 h-48 card bg-secondary justify-center text-secondary-content ">
              <div class="card-body">
                <p class="card-title">{member.character}</p>
                <label>Initiative</label>
                <input id={member.player} className='input input-bordered w-full max-w text-tertiary' placeholeder="..."></input>
              </div>
              </div>)
            )}
          </div>
        </div>
        <div class="basis-1/6 bg-base-200 flex justify-around align-center">
          <button className='btn btn-accent btn-block' onClick={() => saveCombat()}>Fuse</button>
        </div>
    </div>
    <div className="sidebar flex flex-col gap-4">
      <StatusWidget />
        <Spells />
        <MonstersWidget />
      </div>
    </div>
  );
}

export default Encounter;
