// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";


const firebaseConfig = {

  apiKey: "AIzaSyBqZ9uLsXzlPYfEWusLqsulzfNxhNLqDnM",

  authDomain: "blog-6ac0c.firebaseapp.com",

  databaseURL: "https://blog-6ac0c.firebaseio.com",

  projectId: "blog-6ac0c",

  storageBucket: "blog-6ac0c.appspot.com",

  messagingSenderId: "385797007593",

  appId: "1:385797007593:web:dbb79529181690e4fca878",


};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const db = getFirestore(app);

export { auth, db };