
import './App.css';
import {
  BrowserRouter, 
  Routes,
  Route,
  Link
} from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useState, useEffect } from 'react';
// import checkAuth from './components/checkAuth';
import {Login} from './components/login';
import  Dashboard  from './components/pages/Dashboard';
import  Dice  from './components/pages/Dice';
import  Minis  from './components/pages/Minis';
import  Home  from './components/pages/Home';
import  Party  from './components/pages/Party';
import Combat from './components/pages/Combat';
import Encounter from './components/pages/Encounter';
import CustomMonsterBuilder from './components/pages/CustomMonsterBuilder';

function App() {
  const [isAuth, setIsAuth] = useState(false);
  

  const checkAuth = () => {
    const auth = getAuth();
    auth.onAuthStateChanged(user => {
      if (user) {
        console.log('set true in checkeraurth');
        setIsAuth(true);
      } else {
        console.log('set false');
        setIsAuth(false);
      }
    });}

  useEffect(() => {
    checkAuth();
  }, [isAuth]);

  return (
    <BrowserRouter>
    <div className="navbar bg-primary text-primary-content">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabindex="0" className="btn btn-ghost btn-circle">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
          </label>
          <ul tabindex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 text-white">
          {!isAuth ? '' :<li><Link to="/Home">Home</Link></li>}
          {!isAuth ? '' :<li><Link to="/Dashboard">DnD Monster Manual</Link></li>}
          {!isAuth ? '' :<li><Link to="/Encounter">DnD Encounter Builder</Link></li>}
          {!isAuth ? '' :<li><Link to="/Combat">DnD Combat Tracker</Link></li>}
          {!isAuth ? '' :<li><Link to="/Party">DnD Party manager</Link></li>}
          {!isAuth ? '' :<li><Link to="/CustomMonster">Custom Monster Builder</Link></li>}
          
          <li><Link to="/Dice">Dice</Link></li>
          <li><Link to="/Minis">Minis</Link></li>
          </ul>
        </div>
      </div>
      <div className="navbar-center">
        <a href='#' className="btn btn-ghost normal-case text-xl">Tortles Wisdom</a>
      </div>
      <Login />
    </div>
    <Routes>
      <Route path="/Home" element={<Home />}>
      </Route>
      <Route path="/Dashboard" element={<Dashboard />}>
      </Route>
      <Route path="/Encounter" element={<Encounter />}>
      </Route>
      <Route path="/Combat" element={<Combat />}>
      </Route>
      <Route path="/CustomMonster" element={<CustomMonsterBuilder />}>
      </Route>
      <Route path="/Dice" element={<Dice />}>

      </Route>
      <Route path="/Minis" element={<Minis />}>

      </Route>
      <Route path="/Party" element={<Party />}>

      </Route>
    </Routes>

    </BrowserRouter>
  );
}

export default App;


