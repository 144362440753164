import React from 'react';
import Spells from '../toolbar/Spells';
import StatusWidget from '../toolbar/StatusWidget';
import MonstersWidget from '../toolbar/MonstersWidget';
import '../../index.css';
import '../../App.css';

import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc, getDocs, query, where, doc, deleteDoc } from "firebase/firestore";
import { db, auth } from '../FirebaseConfig';
import { map } from '@firebase/util';

const Combat = () => {
  const [combat, setCombat] = useState([]);
  const [singleCombat, setSingleCombat] = useState({});
  const [monster, setMonster] = useState({});
  const [addMonster, setAddMonster] = useState({
    name: '',
    hit_points: '',
    ac: '',
    initiative: '',
    url: '',
    //fix dit
  });

  const getCombatFromDb = async () => {
    setCombat([]);
    const uid = auth.currentUser.uid;
    const q = query(collection(db, "combat"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      setCombat(combat => [...combat, doc.data()]);
      console.log(combat);
    }
    );
  }
  const notify = (message) => toast(message);

  const sortInitiative = () => {

    const sortedCombat = singleCombat.chars.sort((a, b) => (parseInt(a.initiative) > parseInt(b.initiative)) ? -1 : 1);
    setSingleCombat({ ...singleCombat, chars: sortedCombat });
    console.log(singleCombat);
  }

  const deleteCombatFromDb = (index) => {
    console.log("index is: " + index);
    const uid = auth.currentUser.uid;
    const q = query(collection(db, "combat"), where("uid", "==", uid));
    const querySnapshot = getDocs(q);
    querySnapshot.then((querySnapshot) => {
      querySnapshot.forEach((docS) => {
        if (docS.data().combatName === index) {
          const docRef = doc(db, "combat", docS.id);
          deleteDoc(docRef)
            .then(() => {
              console.log("Entire Document has been deleted successfully.")
            }).catch(error => {
              console.log(error);
            }).then(() => {
              getCombatFromDb();
            });
        }
      });
    });
  }



  const getSingleCombatFromDb = async (combatName) => {
    const uid = auth.currentUser.uid;
    const q = query(collection(db, "combat"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      if (doc.data().combatName === combatName) {
        setSingleCombat(doc.data());
        console.log(singleCombat);
      }
    }

    );

  }
  const handleChange = (index, e) => {
    const neData = [...singleCombat.chars];

    if (neData[index].hit_points > 0) {
      neData[index].hit_points = e.target.value;
    }
    else {
      neData[index].hp = e.target.value;
    }

    setSingleCombat({ ...singleCombat, chars: neData });
  }



  const getSingleMonsterApi = async (url) => {
    const response = await fetch(`https://www.dnd5eapi.co${url}`);
    const data = await response.json();
    setMonster(data);
    console.log(monster);
  }
  const addMonsterToCombat = () => {
    setSingleCombat({ ...singleCombat, chars: [...singleCombat.chars, addMonster] });

    console.log(singleCombat);

  }

  useEffect(() => {
    getCombatFromDb();
  }, []);
  return (
    <div className="App">

      <input type="checkbox" id="my-modal9" className="modal-toggle" />
      <div className="modal z-10">
        <div className="modal-box w-11/12 max-w-5xl">
          <div className="modal-action z-10">
            <label htmlFor="my-modal9" className="btn">Close!</label>
          </div>
          <div className="modal-body z-10">
            <div class="basis-6/6 bg-base-300">
              <div class="hero mt-4">
                <div class="hero-overlay bg-opacity-60 background-image-dashboard rounded-box"></div>
                <div class="hero-content text-center text-neutral-content">
                  <div class="max-w-md">
                    <h1 class="mb-5 text-5xl font-bold text-tertiary">{!monster.name ? 'loading' : monster.name}</h1>
                  </div>
                </div>

              </div>

              <div class="flex flex-row gap-4 mt-4">
                <div class="basis-1/4 bg-base-200 p-4 flex justify-start flex flex-col rounded-box">
                  <h2 className='mb-5 text-2xl font-bold text-secondary'>Info</h2>
                  <ul>
                    <li><strong>Size: </strong> {monster.name ? 'loading' : monster.size}</li>
                    <li><strong>Type: </strong> {!monster.name ? 'loading' : monster.type}</li>
                    <li><strong>Alignment: </strong> {!monster.name ? 'loading' : monster.alignment}</li>
                    <li><strong>Armor Class: </strong> {!monster.name ? 'No record yet' : monster.armor_class[0].value}</li>
                    <li><strong>Hit Points: </strong> {!monster.name ? 'loading' : monster.hit_points}</li>
                    <li><strong>Hit Dice: </strong> {!monster.name ? 'loading' : monster.hit_dice}</li>
                    <li><strong>Walk speed: </strong> {!monster.name ? '' : monster.speed.walk}</li>
                    <li><strong>Fly speed: </strong> {!monster.name ? '' : monster.speed.fly}</li>
                    <li><strong>Swim speed: </strong> {!monster.name ? '' : monster.speed.swim}</li>
                    <li><strong>Languages: </strong> {monster.languages}</li>
                  </ul>
                </div>
                <div class="basis-1/4 bg-base-200 p-4 flex justify-start flex flex-col rounded-box">
                  <h2 className='mb-5 text-2xl font-bold text-secondary'>Statblock</h2>
                  <ul>
                    <li><strong>Strength: </strong> {!monster.name ? 'loading' : monster.strength}</li>
                    <li><strong>Dexterity: </strong> {!monster.name ? 'loading' : monster.dexterity}</li>
                    <li><strong>Constitution: </strong> {!monster.name ? 'loading' : monster.constitution}</li>
                    <li><strong>Intelligence: </strong> {!monster.name ? 'loading' : monster.intelligence}</li>
                    <li><strong>Wisdom: </strong> {!monster.name ? 'loading' : monster.wisdom}</li>
                    <li><strong>Charisma: </strong> {!monster.name ? 'loading' : monster.charisma}</li>
                  </ul>
                </div>
                <div class="basis-1/4 bg-base-200 p-4 flex justify-start flex flex-col rounded-box">
                  <h2 className='mb-5 text-2xl font-bold text-secondary'>Proficiencies</h2>
                  <ul>
                    {!monster.name ? '' : monster.proficiencies.map((proficiency) => (
                      <li>+{proficiency.value} on {proficiency.proficiency.name}</li>
                    ))}
                  </ul>
                </div>

                <div class="basis-1/4 bg-base-200 p-4 flex justify-start flex flex-col rounded-box">
                  <h2 className='mb-5 text-2xl font-bold text-secondary'>Details</h2>
                  <ul>
                    {!monster.name ? '' : monster.damage_vulnerabilities.map((damage) => (
                      <li>Damage vulnerabilities: {damage}</li>
                    ))}
                    {!monster.name ? '' : monster.damage_resistances.map((damage) => (
                      <li>Damage Resistances: {damage}</li>
                    ))}
                    {!monster.name ? '' : monster.damage_immunities.map((damage) => (
                      <li>Damage immunities: {damage.name}</li>
                    ))}
                    {!monster.name ? '' : monster.condition_immunities.map((condition) => (
                      <li>Condition immunities: {condition.name}</li>
                    ))}
                    <li>Blindsight: {!monster.name ? '' : monster.senses.blindsight}</li>
                    <li>Darkvision: {!monster.name ? '' : monster.senses.darkvision}</li>
                    <li>Passive Perception: {!monster.name ? '' : monster.senses.passive_perception}</li>
                  </ul>
                </div>
              </div>
              <div class="flex flex-row gap-4 mt-4">
                <div class="basis-4/4 bg-base-100 rounded-box p-4">
                  <h2 className='mb-5 text-2xl font-bold text-secondary'>Actions</h2>
                  <ul>
                    {!monster.name ? '' : monster.actions.map((action) => (
                      <li className='mb-4'><strong>{action.name}</strong> - {action.desc}</li>
                    ))}
                  </ul>
                </div>

              </div>
              <div class="flex flex-row gap-4 mt-4">
                <div class="basis-4/4 bg-base-100 rounded-box p-4">
                  <h2 className='mb-5 text-2xl font-bold text-secondary'>Special abilities</h2>
                  <ul>
                    {!monster.name ? '' : monster.special_abilities.map((ability) => (
                      <li className='mb-4'><strong>{ability.name}</strong> - {ability.desc}</li>
                    ))}
                  </ul>
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="flex flex-row">
        <div className="basis-1/6">
          <div class="basis-1/6 bg-base-300 p-4 flex-row justify-center">
            <h1 className='m-4'>Saved Combat</h1>
            <ul class="flex mt-4 mb-4 menu menu-compact lg:menu-normal bg-base-100 p-2 rounded-box">
              {combat.map((item, index) => (
                <li class="flex justify-between flex-row m-2" key={index}>
                  <button onClick={() => {
                    getSingleCombatFromDb(item.combatName);
                  }}>{item.combatName}</button>
                  <button className='btn btn-primary' onClick={() => deleteCombatFromDb(item.combatName)}>Delete</button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div class="basis-4/6 bg-base-300 p-4 flex-row justify-center">
          <button className="btn btn-secondary m-3" onClick={(e) => {
            e.preventDefault();
            sortInitiative();
          }
          }>Sort initiative</button>
          <table className="table w-full no-stick-no-z">
            <thead className='no-stick-no-z'>
              <tr className='no-stick-no-z'>
                <th className='no-stick-no-z'>Character</th>
                <th className='no-stick-no-z'>Initiative</th>
                <th className='no-stick-no-z'>HP</th>
              </tr>
            </thead>
            <tbody className='no-stick-no-z'>
              {singleCombat.chars && singleCombat.chars.map((item, index) => (
                <tr key={index}>
                  <td className='no-stick-no-z'>{!item.name ? item.character : item.name}{!item.character ? item.monstername : null} {item.counter} {!item.name ? null : <label htmlFor="my-modal9" className="btn modal-button ml-8" onClick={() => getSingleMonsterApi(item.url)}>Monster info</label>}</td>
                  <td className='no-stick-no-z'>{item.initiative}</td>
                  <td className='no-stick-no-z' key={index}><input className="input input-bordered w-full max-w-xs" type="text" onChange={(e) => handleChange(index, e)} value={!item.hit_points ? item.hp : item.hit_points}></input></td>

                </tr>
              ))}

            </tbody>
          </table>
        </div>
        <div class="basis-1/6 bg-base-300 p-4 flex-row justify-center">
          <form>
            <label className="label">
              <span className="label-text">Name</span>
            </label>
            <input className="input input-bordered w-full max-w-xs" type="text" placeholder="Monster name" onChange={(e) => setAddMonster({ ...addMonster, name: e.target.value })}></input>
            <label className="label">
              <span className="label-text">Initiative</span>
            </label>
            <input className="input input-bordered w-full max-w-xs" type="text" placeholder="Initiative" onChange={(e) => setAddMonster({ ...addMonster, initiative: e.target.value })}></input>
            <label className="label">
              <span className="label-text">HP</span>
            </label>
            <input className="input input-bordered w-full max-w-xs" type="text" placeholder="HP" onChange={(e) => setAddMonster({ ...addMonster, hit_points: e.target.value })}></input>
            <label className="label">
              <span className="label-text">AC</span>
            </label>
            <input className="input input-bordered w-full max-w-xs" type="text" placeholder="AC" onChange={(e) => setAddMonster({ ...addMonster, ac: e.target.value })}></input>


            <label className="label">
              <span className="label-text">URL</span>
            </label>
            <input className="input input-bordered w-full max-w-xs" type="text" placeholder="/api/monsternaam" onChange={(e) => setAddMonster({ ...addMonster, url: e.target.value })}></input>

            <button className="btn btn-primary mt-3" onClick={(e) => {
              e.preventDefault();
              addMonsterToCombat();
            }}>Add monster</button>

          </form>

        </div>
      </div>
      <div className="sidebar flex flex-col gap-4">
        <StatusWidget />
        <Spells />
        <MonstersWidget />
      </div>
    </div>
  );
}

export default Combat;
