import React from 'react';
import { useState, useEffect } from 'react';



const Home = () => {
  const [myMiniFactory, setMyMiniFactory] = useState();

  const getMyMiniFactory = async () => {
    fetch("https://www.myminifactory.com/api/v2/users/Annekoeken/collections?key=c1d7553c-dd3b-4aad-b6ad-cdb596dd59f3")
    .then(response => response.json())
    .then(data => setMyMiniFactory(data))
  }
  useEffect(() => {
    getMyMiniFactory();
  }, [])

  const lazyConsoleLog = (data) => {
      console.log(data);
  }
  
  https://auth.myminifactory.com/web/authorize?client_id=TortlesWisdom&redirect_uri=http://tortleswisdom.com/callback&response_type=code&state=c1d7553c-dd3b-4aad-b6ad-cdb596dd59f3
  return (
    <div className="App">
      <button onClick={() => lazyConsoleLog(myMiniFactory)}>Log</button>
    </div>
  );
}

export default Home;
