import {useState, useEffect} from 'react';
import '../../index.css';
import '../../App.css';
import './pages.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc, query, where, getDocs } from "firebase/firestore"; 
import {db, auth} from '../FirebaseConfig';

const Dashboard = () => {
  const [monsters, setMonsters] = useState([]);
  const [monster, setMonster] = useState({});
  const [customMonsters, setCustomMonsters] = useState([]);
  const [encounter, setEncounter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [play, setPlay] = useState(false);
  const {buttonSucces, setButtonSuccess} = useState('btn btn-secondary');
  const [showModal, setShowModal] = useState(false);


  const [counter, setCounter] = useState(1);

  const getMonstersApi = async () => {
    fetch('https://www.dnd5eapi.co/api/monsters')
       .then(response => response.json())
       .then(data => setMonsters(data.results))
       .catch(error => console.log(error));
   }
   const getMonsterApiDetails = async (id) => {
       console.log('search '+ id);
       fetch(`https://www.dnd5eapi.co/api/monsters/${id}`)
       .then(response => response.json())
       .then(data => setMonster(data))
       .catch(error => console.log(error));
       console.log(monster.name);
       setCounter(1);
   }
   const getCustomMonstersFromDb = async () => {
        const uid = auth.currentUser.uid;
        const q = query(collection(db, "monsters"), where("uid", "==", uid));
        setCustomMonsters([]);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            setCustomMonsters(customMonsters => [...customMonsters, doc.data()]);
            console.log(customMonsters);
        }
        );
    }



   const notify = (message) => toast(message);

  const addCustomToEncounter = (monster) => {
    monster = {...monster, counter: counter};
    setEncounter(encounter => [...encounter, monster]);
   
    setCounter(counter => counter + 1);
  }
   const addToEncounter = (monster) => {
        monster = {
            ...monster,
            initiative: calcInitiative(monster.dexterity),
        }
        monster = {...monster, counter: counter};
        setEncounter(encounter => [...encounter, monster]);
       
        setCounter(counter => counter + 1);
        console.log(encounter);
        notify('Monster added to encounter');
    }

    const calcInitiative = (initiative) => {
      let setter = 0;
      if (initiative > 26)  {
        setter = 8;
      }
      else if(initiative > 24){
        setter = 7;
      }
      else if(initiative > 22){
        setter = 6;
      }
      else if(initiative > 20){
        setter = 5;
      }
      else if(initiative > 18){
        setter = 4;
      }
      else if(initiative > 16){
        setter = 3;
      }
      else if(initiative > 14){
        setter = 2;
      }
      else if(initiative > 12){
        setter = 1;
      }
      else if(initiative > 10){
        setter = 0;
      }
      else if(initiative > 8){
        setter = -1;
      }
      else if(initiative > 6){
        setter = -2;
      }
      else if(initiative > 4){
        setter = -3;
      }
      else if(initiative > 2){
        setter = -4;
      }
      else if(initiative > 0){
        setter = -5;
      }
      else {
        setter = 0;
      }
      console.log(setter);
      const random = Math.floor(Math.random() * 20) + 1;
      let total = random + setter;	
      return total;
    }

    const removeFromEncounter = (monster) => {
        setEncounter(encounter => encounter.filter(m => m.name !== monster.name));
        notify('Monster removed from encounter');
    }

    const showModalfunction = () => {
        setShowModal(true);
    }
    
    const addEncounterToDb = async () => {
        setShowModal(false);
        notify('Encounter added to database');
        const encounterName = document.getElementById('encounterName').value;
        const uid = auth.currentUser.uid;
        
        const docRef = collection(db, "encounters");
        const doc = await addDoc(docRef, {
            encounterName: encounterName,
            uid: uid,
            monsters: encounter 
        });
        setEncounter([]);
        setCounter(1);
        
    }
   useEffect(() => {
      getMonstersApi();
      getCustomMonstersFromDb();
    }, [])

  return (
    <div className="App">
      <ToastContainer limit={1}/>

      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Set encounter name
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-col">
                    <input

                      id="encounterName" 
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="Encounter name"
                    />
                    
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-secondary text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => addEncounterToDb()}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div class="flex flex-row">
        <div class="basis-1/6 bg-base-300 flex flex-col flex-start pr-2">
        <div className="collapse">
          <input type="checkbox" className="peer" /> 
            <div className="collapse-title bg-secondary text-primary-content peer-checked:bg-secondary peer-checked:text-secondary-content">
              <h3 className='text-l font-bold text-white'>Custom monsters</h3>
            </div>
            <div className="collapse-content bg-primary text-primary-content peer-checked:bg-secondary peer-checked:text-secondary-content"> 
            <ul class="flex mt-4 mb-4 menu menu-compact lg:menu-normal bg-base-100 p-2 rounded-box overflow-y-scroll" style={{height: '50vh'}}>
                    {customMonsters.map((monster, index) => (
                      <li class="flex items-start w-56 p-2">
                        <a class="flex items-center" href="#" onClick={() => addCustomToEncounter(monster)}>
                          <span id={monster.url}>{!monster.name ? 'loading' : monster.name }</span>
                        </a>
                      </li>
                    ))}
                  </ul>
            </div>
          </div>
        <div className="collapse">
          <input type="checkbox" className="peer" /> 
            <div className="collapse-title bg-accent text-primary-content peer-checked:bg-secondary peer-checked:text-secondary-content">
                <h3 className='text-l font-bold text-white'>Monster Manual</h3>
            </div>
            <div className="collapse-content bg-primary text-primary-content peer-checked:bg-secondary peer-checked:text-secondary-content"> 
            <ul class="flex mt-4 mb-4 menu menu-compact lg:menu-normal bg-base-100 p-2 rounded-box overflow-y-scroll" style={{height: '50vh'}}>
                    {monsters.map((monster, index) => (
                      <li class="flex items-start w-56 p-2">
                        <a class="flex items-center" href="#" onClick={() => getMonsterApiDetails(monster.url.split('/').pop())}>
                          <span id={monster.url}>{!monster.name ? 'loading' : monster.name }</span>
                        </a>
                      </li>
                    ))}
                  </ul>
            </div>
          </div>
        

        </div>
        <div class="basis-4/6 bg-base-300" style={{height: '94vh'}}>
          <div class="hero mt-4">
            <div class="hero-overlay bg-opacity-60 background-image-dashboard rounded-box"></div>
            <div class="hero-content text-center text-neutral-content">
              <div class="max-w-md">
                <h1 class="mb-5 text-5xl font-bold text-tertiary">{!monster.name ? 'loading' : monster.name}</h1>
              </div>
            </div>
            
          </div>
          <div class="flex flex-row gap-4 mt-4">
          <div class="basis-1/4 bg-base-200 p-4 flex justify-start flex flex-col rounded-box">
              <h2 className='mb-5 text-2xl font-bold text-secondary'>Info</h2>
              <ul>
                <li><strong>Size: </strong> {monster.name ? 'loading' : monster.size}</li>
                <li><strong>Type: </strong> {!monster.name ? 'loading' : monster.type}</li>
                <li><strong>Alignment: </strong> {!monster.name ? 'loading' : monster.alignment}</li>
                <li><strong>Armor Class: </strong> {!monster.name ? 'loading' : monster.armor_class[0].value}</li>
                <li><strong>Hit Points: </strong> {!monster.name ? 'loading' : monster.hit_points}</li>
                <li><strong>Hit Dice: </strong> {!monster.name ? 'loading' : monster.hit_dice}</li>
                <li><strong>Walk speed: </strong> {!monster.name ? '' : monster.speed.walk}</li>
                <li><strong>Fly speed: </strong> {!monster.name ? '' : monster.speed.fly}</li>
                <li><strong>Swim speed: </strong> {!monster.name ? '' : monster.speed.swim}</li>
                <li><strong>Languages: </strong> {monster.languages}</li>
              </ul>
              </div>
              <div class="basis-1/4 bg-base-200 p-4 flex justify-start flex flex-col rounded-box">
              <h2 className='mb-5 text-2xl font-bold text-secondary'>Statblock</h2>
              <ul>
                <li><strong>Strength: </strong> {!monster.name ? 'loading' : monster.strength}</li>
                <li><strong>Dexterity: </strong> {!monster.name ? 'loading' : monster.dexterity}</li>
                <li><strong>Constitution: </strong> {!monster.name ? 'loading' : monster.constitution}</li>
                <li><strong>Intelligence: </strong> {!monster.name ? 'loading' : monster.intelligence}</li>
                <li><strong>Wisdom: </strong> {!monster.name ? 'loading' : monster.wisdom}</li>
                <li><strong>Charisma: </strong> {!monster.name ? 'loading' : monster.charisma}</li>
              </ul>
              </div>
              <div class="basis-1/4 bg-base-200 p-4 flex justify-start flex flex-col rounded-box">
              <h2 className='mb-5 text-2xl font-bold text-secondary'>Proficiencies</h2>
              <ul>
                {!monster.name ? '' : monster.proficiencies.map((proficiency) => (
                  <li>+{proficiency.value} on {proficiency.proficiency.name}</li>
                ))}
              </ul>
              </div>
   
              <div class="basis-1/4 bg-base-200 p-4 flex justify-start flex flex-col rounded-box">
              <h2 className='mb-5 text-2xl font-bold text-secondary'>Details</h2>
              <ul>
                {!monster.name ? '' : monster.damage_vulnerabilities.map((damage) => (
                  <li>Damage vulnerabilities: {damage}</li>
                ))}
                {!monster.name ? '' : monster.damage_resistances.map((damage) => (
                  <li>Damage Resistances: {damage}</li>
                ))}
                {!monster.name ? '' : monster.damage_immunities.map((damage) => (
                  <li>Damage immunities: {damage.name}</li>
                ))}
                {!monster.name ? '' : monster.condition_immunities.map((condition) => (
                  <li>Condition immunities: {condition.name}</li>
                ))}
                <li>Blindsight: {!monster.name ? '' : monster.senses.blindsight}</li>
                <li>Darkvision: {!monster.name ? '' : monster.senses.darkvision}</li>
                <li>Passive Perception: {!monster.name ? '' : monster.senses.passive_perception}</li>
                </ul>
              </div>
          </div>
          <div class="flex flex-row gap-4 mt-4">
          <div class="basis-4/4 bg-base-100 rounded-box p-4">
              <h2 className='mb-5 text-2xl font-bold text-secondary'>Actions</h2>
              <ul>
                {!monster.name ? '' : monster.actions.map((action) => (
                  <li className='mb-4'><strong>{action.name}</strong> - {action.desc}</li>
                ))}
              </ul>
          </div>
    
          </div>
          <div class="flex flex-row gap-4 mt-4">
          <div class="basis-4/4 bg-base-100 rounded-box p-4">
              <h2 className='mb-5 text-2xl font-bold text-secondary'>Special abilities</h2>
              <ul>
                {!monster.name ? '' : monster.special_abilities.map((ability) => (
                  <li className='mb-4'><strong>{ability.name}</strong> - {ability.desc}</li>
                ))}
              </ul>
          </div>
         
          </div>
        </div>
        <div class="basis-1/6 bg-base-300">
        <div class="flex flex-col gap-4 mt-4">
            <div class="flex flex-row gap-4 mt-4 justify-center">
                  <button onClick={() =>{addToEncounter(monster)}} className='btn btn-primary'>Add to encounter</button>
                  <button onClick={() =>{showModalfunction()}} className='btn btn-accent'  >Save</button>
            </div>
            <ul class="menu bg-secondary text-secondary-content p-2 ml-8 mr-8 mt-4 rounded-box">
              {encounter.map((monster) => (
               
                  <li onClick={() => removeFromEncounter(monster)}><a>{!monster.name ? monster.name + ' ' + monster.counter : monster.name + ' ' + monster.counter}</a></li>         
                
           
              ))}
             </ul>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
