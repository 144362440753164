import { async } from '@firebase/util';
import { useState, useEffect } from 'react';
import './toolbar.css';

const Spells = () => {
    const [spells, setSpells] = useState([]);
    const [spell, setSpell] = useState({});

    const [query, setQuery] = useState("");


    const getSpells = async () => {
     fetch('https://www.dnd5eapi.co/api/spells')
        .then(response => response.json())
        .then(data => setSpells(data.results))
        .catch(error => console.log(error));
    }
    const getSpellDetails = async (id) => {
        console.log('search '+ id);
        fetch(`https://www.dnd5eapi.co/api/spells/${id}`)
        .then(response => response.json())
        .then(data => setSpell(data))
        .catch(error => console.log(error));
    }
    useEffect(() => {
        console.log(query);
    }  , [query]);  

    return (
        <div>
        <label onClick={() => getSpells()} for="my-modal" class="btn btn-secondary modal-button">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
         <path stroke-linecap="round" stroke-linejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
        </svg>

        </label>
                <input type="checkbox" id="my-modal" class="modal-toggle" />
                <div class="modal">
                 <div class="modal-box w-11/12 max-w-5xl">
                 <div class="flex flex-row gap-4">
                        <div class="basis-1/4">
                        <input onChange={e => setQuery(e.target.value)} type="text" placeholder="Search..." class="input input-bordered input-primary w-full max-w-xs mt-2 mb-2" />
                            <ul className=''>
                            {spells.filter(spell => spell.name.includes(query)).map(filteredSpell => (
                                <li onClick={() => getSpellDetails(filteredSpell.index)}key={filteredSpell.index}>
                                {filteredSpell.name}
                                </li>
                            ))} 
                            </ul>
                        
                        </div>
                        <div class="basis-3/4">
                                        <div class="flex flex-row justify-between">
                                   
                                        {spell.name ? <h1>{spell.name}</h1> : 'Title'}
                                        <label for="my-modal" class="btn">X</label>
                                        </div>
                                    <div class="flex flex-col">
                                    
                    
                       
                            <h2>Description</h2>     
                            {spell.name ?<p>{spell.desc}</p> : 'Description'}
                            <h2>Range</h2>     
                            {spell.name ?<p>{spell.range}</p> : 'No range'}
                            <h2>Materials</h2>     
                            {spell.name ?<p>{spell.material}</p> : 'No Materials'}
                            <h2>Damage</h2>     
                            {spell.name ?<p>{spell.damage ? spell.damage.damage_type.name : 'No damage'}</p> : 'No Damage'}
                            </div>
                        </div>
                        </div>
                    <div class="modal-action">
                   
                    </div>
                </div>
                </div>
        </div>
    );
    
}

export default Spells;