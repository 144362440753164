import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { useState } from "react";
import { auth } from "./FirebaseConfig";
import { useNavigate  } from "react-router-dom";

const Login = () => {
    const [displayName, setDisplayName] = useState('');
    const [authenticated , setAuthenticated] = useState(false);
    const [token, setToken] = useState(null);
    
    const provider = new GoogleAuthProvider();
    let navigate = useNavigate();
    const signOutUser = () => {
        console.log('signing out');
        const auth = getAuth();
            signOut(auth).then(() => {
                setDisplayName('');
                setAuthenticated(false);
                navigate("/"); 
            }).catch((error) => {
                console.log('kaas');
            });
    }
    const LoginWithGoogle = () => {
        signInWithPopup(auth, provider)
          .then((result) => {
            
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            console.log(result.user);
            setToken(credential.accessToken);
            // The signed-in user info.
            setDisplayName(result.user.displayName);
            
            setAuthenticated(true);
            navigate("/Home"); 
        // ...
      }).catch((error) => {
        // Handle Errors here.
        
        // ...
      });
    }

        return (
            <div className="navbar-end">
             <p>{authenticated ? displayName : ''}</p>
            {!authenticated ? 
            <button onClick={LoginWithGoogle} className="btn btn-ghost btn-circle">
           
           <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
               <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
           </svg>       
           Login
           </button>
          : null}
           <p>{authenticated ?  <button onClick={signOutUser} className="btn btn-ghost btn-circle">
          <div className="indicator">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
          </div>
        </button> : null}</p>   
       
      </div>


        );

}

export {Login} ;