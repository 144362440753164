
import { useEffect, useState } from 'react';
import { collection, addDoc, getDocs, doc, deleteDoc, query, where } from "firebase/firestore"; 
import {db, auth} from '../FirebaseConfig';
import Spells from '../toolbar/Spells';
import StatusWidget from '../toolbar/StatusWidget';
import MonstersWidget from '../toolbar/MonstersWidget';
import '../../index.css';

const CustomMonsterBuilder = () => {
    const [monsters, setMonsters] = useState([]);
    const [monster, setMonster] = useState({});


    const getMonstersFromDb = async () => {
        const uid = auth.currentUser.uid;
        const q = query(collection(db, "monsters"), where("uid", "==", uid));
        setMonsters([]);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            setMonsters(monsters => [...monsters, doc.data()]);
            console.log(monsters);
        }
        );
    }


    

      const saveMonsterToDb = async () => {
        const Monstername = document.getElementById('monster').value;
        const Ac = document.getElementById('ac').value;
        const hp = document.getElementById('hp').value
        const url = document.getElementById('url').value
        const init = document.getElementById('init').value
        const uid = auth.currentUser.uid;
        const setTemp = {
            name: Monstername,
            ac: Ac,
            hp: hp,
            initiative: init,
            url: url,
            uid: uid
        }
        setMonster(setTemp);
        const q = collection(db, "monsters");
        await addDoc(q, setTemp);
        console.log("Document written with ID: ", q.id);
        getMonstersFromDb();
      }
      const deleteMonster = async (charid) => {
        console.log("charid is = " + charid);
        const q = query(collection(db, "monsters"), where("name", "==", charid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((instant) => {
            const docRef = doc(db, "monsters", instant.id);
            deleteDoc(docRef)
            .then(() => {
                console.log("Entire Document has been deleted successfully.")
            }).catch(error => {
                console.log(error);
            }).then(() => {
                getMonstersFromDb();
            }).catch(error => {
                console.log(error);
            }).then(() => {
                setMonster({});
            }).catch(error => {
                console.log(error);
            }).then(() => {
                setMonsters([]);
            }).catch(error => {
                console.log(error);
            } ) ;
          
      }, [monsters]);} 
    //   const updateMonster = async () => {
    //     const uid = auth.currentUser.uid;
    //     const q = collection(db, "players");
    //     await q.doc(player.uid).update({
    //         passive: player.passive,
    //         character: player.character,
    //         player: player.player,
    //         ac: player.ac,
    //         hp: player.hp,
    //         uid: uid
    //     });
    //     console.log("Document written with ID: ", q.id);
    //   }

        useEffect(() => {
            getMonstersFromDb();
        }
        , []);
        
        return (
            <div className="flex">
              <div className='basis-1/4 flex flex-col gap-4'>
              <div class="form-control flex flex-col w-full bg-base-300 p-4 m-4 justify-between">

                  <label class="input-group max-w w-full m-4">
                    <span>Name monster</span>
                    <input  className='input input-bordered w-full max-w' id='monster' type="text" placeholder="Fin Diessel" class="input input-bordered" />
                  </label>
                  <label class="input-group max-w w-full m-4">
                                        <span>HP</span>
                    <input  className='input input-bordered w-full max-w' id='hp' type="text" placeholder="Vin Diessel" class="input input-bordered" />
                  </label>
                  <label class="input-group max-w w-full m-4">
                                        <span>AC</span>
                    <input  className='input input-bordered w-full max-w' id='ac' type="text" placeholder="> 9000" class="input input-bordered" />
                  </label>
                  <label class="input-group max-w w-full m-4">
                                        <span>Initiative</span>
                    <input  className='input input-bordered w-full max-w' id='init' type="text" placeholder="> 9000" class="input input-bordered" />
                  </label>
                  <label class="input-group max-w w-full m-4">
                                        <span>API URL</span>
                    <input className='input input-bordered w-full max-w' id='url' type="text" placeholder="2" class="input input-bordered" />
                  </label>
                 
                  <button className='btn' onClick={() => saveMonsterToDb()}>Save</button>

                </div>
              </div>
              <div className='basis-3/4 flex flex-col gap-4 ml-4 flex-wrap'>
                <div className='flex flex-wrap flex-row gap-4'> 
                  {monsters.map((monster, index) => (
                     <div key={index} className= "m-4 w-96 h-64 card justify-center text-primary-content bg-secondary">
                     <div class="card-body">
                       <p class="card-title">{monster.name}</p>
                       <p class="card-text">AC: {monster.ac}</p>
                       <p class="card-text">HP: {monster.hp}</p>
                        <p class="card-text">Initiative: {monster.initiative}</p>
                       <p class="card-text">URL{monster.url}</p>
                      <button className='btn' onClick={() => deleteMonster(monster.name)}>Delete</button>
                     </div>
                   </div>
                  ))}
                </div>
              </div>
              <div className="sidebar flex flex-col gap-4">
            <StatusWidget />
                <Spells />
                <MonstersWidget />
                </div>
            </div>
        );

}
export default CustomMonsterBuilder;