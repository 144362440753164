import { async } from '@firebase/util';
import { useState, useEffect } from 'react';
import './toolbar.css';

const StatusWidget = () => {
    const [conditions, setconditions] = useState([]);
    const [condition, setcondition] = useState({});

    const [query, setQuery] = useState("");


    const getSpells = async () => {
     fetch('https://www.dnd5eapi.co/api/conditions')
        .then(response => response.json())
        .then(data => setconditions(data.results))
        .catch(error => console.log(error));
    }
    const getSpellDetails = async (id) => {
        console.log('search '+ id);
        fetch(`https://www.dnd5eapi.co/api/conditions/${id}`)
        .then(response => response.json())
        .then(data => setcondition(data))
        .catch(error => console.log(error));
    }
    useEffect(() => {
        console.log(query);
    }  , [query]);  

    return (
        <div>
        <label onClick={() => getSpells()} for="my-modal3" class="btn btn-secondary modal-button">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
</svg>

        </label>
                <input type="checkbox" id="my-modal3" class="modal-toggle" />
                <div class="modal">
                 <div class="modal-box w-11/12 max-w-5xl">
                 <div class="flex flex-row gap-4">
                        <div class="basis-1/4">
                        <input onChange={e => setQuery(e.target.value)} type="text" placeholder="Search..." class="input input-bordered input-primary w-full max-w-xs mt-2 mb-2" />
                            <ul className=''>
                            {conditions.filter(spell => spell.name.includes(query)).map(filteredSpell => (
                                <li onClick={() => getSpellDetails(filteredSpell.index)}key={filteredSpell.index}>
                                {filteredSpell.name}
                                </li>
                            ))} 
                            </ul>
                        
                        </div>
                        <div class="basis-3/4">
                                        <div class="flex flex-row justify-between">
                                   
                                        {condition.name ? <h1>{condition.name}</h1> : 'Title'}
                                        <label for="my-modal3" class="btn">X</label>
                                        </div>
                                    <div class="flex flex-col">
                            
                                    <h2>Description</h2>
                                    <div class="flex flex-row">
                                    <div class="basis-1/4">
                                    {condition.name}
                                    </div>
                                    <div class="basis-3/4">
                                    {condition.desc}
                                    </div>
                                    </div>
                                    
                              
                                    </div>
                                   
                                    
                                 

                               

                       
                  
                            </div>
                        </div>
                  
                    <div class="modal-action">
                   
                    </div>
                </div>
                </div>
        </div>
    );
    
}

export default StatusWidget