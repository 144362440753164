
import { useEffect, useState } from 'react';
import { collection, addDoc, getDocs, doc, deleteDoc, query, where } from "firebase/firestore"; 
import {db, auth} from '../FirebaseConfig';
import '../../index.css';

const Party = () => {
    const [players, setPlayers] = useState([]);
    const [player, setPlayer] = useState({});


    const getPlayersFromDb = async () => {
        const uid = auth.currentUser.uid;
        const q = query(collection(db, "players"), where("uid", "==", uid));
        setPlayers([]);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            setPlayers(players => [...players, doc.data()]);
            console.log(players);
        }
        );
    }


    

      const savePlayers = async () => {
        const character = document.getElementById('character').value;
        const player = document.getElementById('player').value;
        const ac = document.getElementById('ac').value
        const hp = document.getElementById('hp').value
        const passive = document.getElementById('passive').value
        const uid = auth.currentUser.uid;
        const setTemp = {
            passive: passive,
            character: character,
            player: player,
            ac: ac,
            hp: hp,
            uid: uid
        }
        setPlayer(setTemp);
        const q = collection(db, "players");
        await addDoc(q, setTemp);
        console.log("Document written with ID: ", q.id);
        getPlayersFromDb();
      }
      const deletePlayer = async (charid) => {
        const q = query(collection(db, "players"), where("character", "==", charid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((instant) => {
            const docRef = doc(db, "players", instant.id);
            deleteDoc(docRef)
            .then(() => {
                console.log("Entire Document has been deleted successfully.")
            }).catch(error => {
                console.log(error);
            }).then(() => {
                getPlayersFromDb();
            }).catch(error => {
                console.log(error);
            }).then(() => {
                setPlayer({});
            }).catch(error => {
                console.log(error);
            }).then(() => {
                setPlayers([]);
            }).catch(error => {
                console.log(error);
            } ) ;
          
      }, [players]);} 
      const updatePlayer = async () => {
        const uid = auth.currentUser.uid;
        const q = collection(db, "players");
        await q.doc(player.uid).update({
            passive: player.passive,
            character: player.character,
            player: player.player,
            ac: player.ac,
            hp: player.hp,
            uid: uid
        });
        console.log("Document written with ID: ", q.id);
      }

        useEffect(() => {
            getPlayersFromDb();
        }
        , []);
        
        return (
            <div className="flex">
              <div className='basis-1/4 flex flex-col gap-4'>
              <div class="form-control flex flex-col w-full bg-base-300 p-4 m-4 justify-between">

                  <label class="input-group max-w w-full m-4 justify-start">
                    <span>Name Character</span>
                    <input  className='input input-bordered w-full max-w' id='character' type="text" placeholder="Fin Diessel" class="input input-bordered" />
                  </label>
                  <label class="input-group max-w w-full m-4">
                                        <span>Name Player</span>
                    <input  className='input input-bordered w-full max-w' id='player' type="text" placeholder="Vin Diessel" class="input input-bordered" />
                  </label>
                  <label class="input-group max-w w-full m-4">
                                        <span>AC Character</span>
                    <input  className='input input-bordered w-full max-w' id='ac' type="text" placeholder="> 9000" class="input input-bordered" />
                  </label>
                  <label class="input-group max-w w-full m-4">
                                        <span>Passive perception Character</span>
                    <input className='input input-bordered w-full max-w' id='passive' type="text" placeholder="2" class="input input-bordered" />
                  </label>
                  <label class="input-group max-w w-full m-4">                    <span>HP Character</span>
                    <input className='input input-bordered w-full max-w' id='hp' type="text" placeholder="45" class="input input-bordered" />
                  </label>
                  <button className='btn' onClick={() => savePlayers()}>Save</button>

                </div>
              </div>
              <div className='basis-3/4 flex flex-col gap-4 ml-4 flex-wrap'>
                <div className='flex flex-wrap flex-row gap-4'> 
                  {players.map((player, index) => (
                     <div key={index} className= "m-4 w-96 h-48 card justify-center text-primary-content bg-secondary">
                     <div class="card-body">
                       <p class="card-title">{player.character + ' - ' + player.player}</p>
                       <p class="card-text">AC: {player.ac}</p>
                       <p class="card-text">Passive perception: {player.passive}</p>
                       <p class="card-text">HP: {player.hp}</p>
                      <button className='btn' onClick={() => deletePlayer(player.character)}>Delete</button>
                     </div>
                   </div>
                  ))}
                </div>
              </div>
            </div>
        );

}
export default Party;