import { async } from '@firebase/util';
import { useState, useEffect } from 'react';
import Favs from './Favs';
import './toolbar.css';
import { collection, query, where, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db, auth } from '../FirebaseConfig';

const MonstersWidget = () => {
    const [monsters, setMonsters] = useState([]);
    const [monster, setMonster] = useState({});

    const [searchQuery, setSearchQuery] = useState("");

    const [favs, setFavs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getFavos();
    }, [loading])

    const getFavos = async () => {
        const uid = auth.currentUser.uid;
        const q = query(collection(db, "favs"), where("uid", "==", uid));
        setFavs([]);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            setFavs(favs => [...favs, doc.data()]);
        });
    }
    const deleteFavs = async (name) => {
        setLoading(true);
        const q = query(collection(db, "favs"), where("name", "==", name));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((instant) => {
            const docRef = doc(db, "favs", instant.id);
            deleteDoc(docRef)
                .then(() => {
                    console.log("Entire Document has been deleted successfully.")
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                })
        });
    }

    const getSpells = async () => {
        fetch('https://www.dnd5eapi.co/api/monsters')
            .then(response => response.json())
            .then(data => setMonsters(data.results))
            .catch(error => console.log(error));
    }
    const getSpellDetails = async (id) => {
        console.log('search ' + id);
        fetch(`https://www.dnd5eapi.co/api/monsters/${id}`)
            .then(response => response.json())
            .then(data => setMonster(data))
            .catch(error => console.log(error));
    }


    return (
        <div className='z-50'>

            <label onClick={() => getSpells()} for="my-modal22" class="btn btn-secondary modal-button">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                </svg>

            </label>
            <input type="checkbox" id="my-modal22" class="modal-toggle" />
            <div class="modal z-50">
                <div class="modal-box w-7xl max-w-7xl z-50">
                    <div class="flex flex-row gap-4">
                        <div class="basis-1/4">
                            <div>
                                <input onChange={e => setSearchQuery(e.target.value)} type="text" placeholder="Search..." class="input input-bordered input-primary w-full max-w-xs mt-2 mb-2" />
                                <ul>
                                    {favs.map(fav => {
                                        return (
                                            <div className="flex flex-row">
                                                <div className="basis-3/4">
                                                    <li onClick={() => getSpellDetails(fav.creature)} id={fav.creature}>{fav.name}</li>
                                                </div>
                                                <div className="basis-1/4">
                                                    <svg onClick={() => deleteFavs(fav.name)} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                </ul>
                            </div>
                            <ul className=''>
                                {monsters.filter(spell => spell.name.includes(searchQuery)).map(filteredSpell => (
                                    <div className='flex flex-row'>
                                        <div className='basis-3/4'>
                                            <li onClick={() => getSpellDetails(filteredSpell.index)} key={filteredSpell.index}>
                                                {filteredSpell.name}
                                            </li>
                                        </div>
                                        <div className='basis-1/4'>
                                            <Favs index={filteredSpell.index} name={filteredSpell.name}></Favs>
                                        </div>
                                    </div>
                                ))}
                            </ul>

                        </div>
                        <div class="basis-3/4">
                            <div class="flex flex-row justify-between">

                                {monster.name ? <h1>{monster.name}</h1> : 'Title'}
                                <label for="my-modal22" class="btn">X</label>
                            </div>
                            <div class="flex flex-col">
                                <strong>URL: {monster.url}</strong>
                                <h2>Description</h2>
                                <div class="flex flex-row">
                                    <div class="basis-1/2">
                                        <p>Size: {monster.size}</p>
                                        <p>Type: {monster.type}</p>
                                        <p>Alignment: {monster.alignment}</p>
                                        <p>Armor Class: {!monster.name ? 'No record yet' : monster.armor_class[0].value}</p>
                                        <p>Hit Points: {monster.hit_points}</p>
                                        <p>Hit Dice: {monster.hit_dice}</p>
                                        <p>Walk Speed: {monster.speed ? monster.speed.walk : 'No walkspeed'}</p>
                                        <p>Walk Speed: {monster.swim ? monster.speed.walk : 'No swimspeed'}</p>
                                        <p>Walk Speed: {monster.fly ? monster.speed.walk : 'No flyspeed'}</p>
                                    </div>
                                    <div class="basis-1/2">
                                        <p>Strength: {monster.strength}</p>
                                        <p>Dexterity: {monster.dexterity}</p>
                                        <p>Constitution: {monster.constitution}</p>
                                        <p>Intelligence: {monster.intelligence}</p>
                                        <p>Wisdom: {monster.wisdom}</p>
                                        <p>Charisma: {monster.charisma}</p>
                                        <br></br>
                                        <p>Challenge: {monster.challenge_rating}</p>
                                    </div>
                                </div>
                                <div class="flex flex-col">
                                    <h2>Actions</h2>
                                    <div class="flex flex-row">
                                        <div class="basis-1/2">

                                            <ul>{monster.actions ? monster.actions.map(action => (
                                                <li key={action.name}>
                                                    {action.name}
                                                </li>
                                            )) : 'No actions'}</ul>
                                        </div>
                                        <div class="basis-1/2">

                                            <ul>{monster.special_abilities ? monster.special_abilities.map(ability => (
                                                <li key={ability.name}>
                                                    {ability.name}
                                                </li>
                                            )) : 'No special abilities'}

                                            </ul>
                                        </div>
                                    </div>

                                </div>








                            </div>
                        </div>
                    </div>
                    <div class="modal-action">

                    </div>
                </div>
            </div>
        </div>
    );


}
export default MonstersWidget;