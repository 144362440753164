import {useState, useEffect} from "react";
import { addDoc, collection } from "firebase/firestore"; 
import { getAuth } from "firebase/auth";
import {db, auth } from '../FirebaseConfig';
const Favs = (props) => {
    const [isFavorite, setIsFavorite] = useState('none');
    const setFav = async () => {
        const uid = auth.currentUser.uid;
        try {
            const docRef = await addDoc(collection(db, "favs"), {
              uid: uid,
            creature: props.index,
            name: props.name
            });
            setIsFavorite('green');
            console.log("Document written with ID: ", docRef.id);
          } catch (e) {
            console.error("Error adding document: ", e);
          }
    }
    return (
        <div onClick={() => setFav()}>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill={isFavorite} viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
            <path stroke-linecap="round" stroke-linejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
            </svg>
        </div>
    )

}
export default Favs;